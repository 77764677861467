import React from 'react'

import { Invoice } from '@guiker/payment-shared'
import { ActivityLabel, Source, useT } from '@guiker/react-framework'
import { Event, formatDateTime } from '@guiker/shared-framework'

import { getInvoiceReason } from '../../utils/get-invoice-reason'

export const transformEventToActivityComponent = ({ event }: { event: Event<Invoice> }) => {
  const { emittedAt, type, data } = event
  const timestamp = formatDateTime(emittedAt)
  const { tMain } = useT({ domain: 'payment', screenName: 'screens.activityLog' })
  const reason = getInvoiceReason({ invoice: data })

  switch (type) {
    case 'CREATED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('CREATED')} />,
      }
    case 'CANCELLED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('CANCELLED')} />,
      }
    case 'CHARGE_PENDING':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('CHARGE_PENDING', { reason })} />,
      }
    case 'CHARGE_SUCCEED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('CHARGE_SUCCEED', { reason })} />,
      }
    case 'CHARGE_FAILED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('CHARGE_FAILED', { reason })} />,
      }
    case 'OFFLINE_CHARGE_SUCCEED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('OFFLINE_CHARGE_SUCCEED', { reason })} />,
      }
    case 'OFFLINE_CHARGE_FAILED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('OFFLINE_CHARGE_FAILED', { reason })} />,
      }
    case 'OFFLINE_CHARGE_UNDONE':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('OFFLINE_CHARGE_UNDONE', { reason })} />,
      }
    case 'REFUND_PENDING':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('REFUND_PENDING', { reason })} />,
      }
    case 'REFUND_FAILED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('REFUND_FAILED', { reason })} />,
      }
    case 'REFUND_SUCEED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('REFUND_SUCEED', { reason })} />,
      }
    case 'IMMEDIATE_CHARGEABLE_CREATED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('IMMEDIATE_CHARGEABLE_CREATED', { reason })} />,
      }
    case 'VERIFICATION_REQUESTED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('VERIFICATION_REQUESTED', { reason })} />,
      }
    case 'VERIFICATION_FAILED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('VERIFICATION_FAILED', { reason })} />,
      }
    case 'VERIFICATION_SUCCEED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('VERIFICATION_SUCCEED', { reason })} />,
      }
    case 'FUNDING_ACCOUNT_CHARGE_PENDING':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('FUNDING_ACCOUNT_CHARGE_PENDING', { reason })} />,
      }
    case 'FUNDING_ACCOUNT_CHARGE_SUCCEED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('FUNDING_ACCOUNT_CHARGE_SUCCEED', { reason })} />,
      }
    case 'FUNDING_ACCOUNT_CHARGE_FAILED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('FUNDING_ACCOUNT_CHARGE_FAILED', { reason })} />,
      }
    case 'STOCK_ORDER_INVOICE_REIMBURSED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('STOCK_ORDER_INVOICE_REIMBURSED', { reason })} />,
      }
    default: {
      return {
        content: <></>,
        label: <></>,
      }
    }
  }
}
